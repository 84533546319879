<template>
    <div>
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'Blanklayout',
    components: {},
    methods: {
        async getLoanApplication() {
            // this.$store.dispatch('loanApplication/getLoanApplication');
        },
        async getCustomer() {
            // this.$store.dispatch('customer/getCustomer');
        },
        async getData() {
            await this.getLoanApplication();
            await this.getCustomer();
        },
    },
    created() {
        this.getData();
    },
};
</script>
